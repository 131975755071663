// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobelpolstring-js": () => import("./../src/pages/mobelpolstring.js" /* webpackChunkName: "component---src-pages-mobelpolstring-js" */),
  "component---src-pages-privatlivs-politik-js": () => import("./../src/pages/privatlivs-politik.js" /* webpackChunkName: "component---src-pages-privatlivs-politik-js" */),
  "component---src-pages-pyntepuder-js": () => import("./../src/pages/pyntepuder.js" /* webpackChunkName: "component---src-pages-pyntepuder-js" */),
  "component---src-pages-tak-js": () => import("./../src/pages/tak.js" /* webpackChunkName: "component---src-pages-tak-js" */)
}

